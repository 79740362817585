import { queryOptions, useQuery } from "@tanstack/react-query";
import databaseService from "../../services/database";

export const databaseQueryOptions = ({ id }) => queryOptions({
  queryKey: ["DATABASE", {
    id,
  }],
  queryFn: async () => {
    const { data } = await databaseService.getDatabase(id);

    return data;
  },
});

const useDatabaseQuery = ({
                       enabled,
                       id
                     }) => {
  const { data, isFetching, isPending, error, isError, refetch } = useQuery({
    ...databaseQueryOptions({ id }),
    enabled,
    retry: 0,
    refetchOnWindowFocus: false,
  })

  return {
    data,
    error,
    isError,
    refetch,
    isFetching,
    isPending
  }
}

export default useDatabaseQuery;
