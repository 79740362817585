import React, { useEffect } from "react";

import { identity } from "lodash";
import trap from "../../../helpers/fp/decorators/trap";

const cache = new Map();
const getSVG = trap((src) => fetch(src, {
  method: "GET",
  headers: {
    "Content-Type": "image/svg+xml",
  },
})
  .then(r => r.text())
  .then((svg) => cache.set(src, svg).get(src))
);

const addClass = (className) => className
  ? (svg) => svg.replace(/<svg /, `<svg class="${className}" `)
  : identity
;

const SVG = ({
  src,
  onLoadend = identity,
  className = "",
  id        = Math.random() * Date.now(),
}) => {
  useEffect(() => {
    const setSvg = (svg) => {
      try {
        const img = document.querySelector("img[src='" + src + "']");
        if (img) {
          img.outerHTML = svg;
        }
      } catch (err) {
        console.log(err, 'error svg')
      }
    };
    if (cache.has(src)) setSvg(addClass(className)(onLoadend(cache.get(src))))
    getSVG(src).then(onLoadend).then(addClass(className)).then(setSvg);
  }, [src]);

  return (<img
    id={id}
    src={src}
    alt="logo"
  />);
};

export default SVG;
