import { queryOptions, useQuery } from "@tanstack/react-query";
import virtualMachineService from "../../services/virtualMachine";

export const virtualMachineQueryOptions = ({ id }) => queryOptions({
  queryKey: ["VM", {
    id
  }],
  queryFn: async () => {
    const { data } = await virtualMachineService.getVirtualMachine(id);

    return data;
  },
});

const useVirtualMachineQuery = ({
                       enabled,
                       id
                     }) => {
  const { data, isFetching, isPending, error, isError, refetch } = useQuery({
    ...virtualMachineQueryOptions({
      id
    }),
    enabled,
    retry: 0,
    refetchOnWindowFocus: false,
  })

  return {
    data,
    error,
    isError,
    refetch,
    isFetching,
    isPending
  }
}

export default useVirtualMachineQuery;
