import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { selectUserThemeId } from "../../../store/user/selector";
import { Logo as IconLogo } from "../../../assets/icons";
import SVG from "../../common/SVG";
import concatPaths from "../../../helpers/fp/url/concatPaths";
import themeApi, { THEME_PUBLIC_URL } from "../../../api/theme";

import "./style.css";

const replaceLogoPath = (path) => path && path.replace(/.+\./, "icon.");

const Icon = () => {
  const themeId = useSelector(selectUserThemeId);

  const {
    0: theme,
    1: setTheme
  } = useState(themeApi.themeData)

  useEffect(() => {
    themeApi.fetchThemeData().then(setTheme);
  }, []);

  const icon = theme?.icon;
  const LOGO = themeId && icon
    ? icon.endsWith(".svg")
      ? <SVG
          src={concatPaths([THEME_PUBLIC_URL + '/' + themeId, replaceLogoPath(icon)])}
          onLoadend={(svg) => svg.replace(/<svg /, `<svg fill="var(--color-black)" `)}
          className="icon"
        />
      : <img
          src={concatPaths([THEME_PUBLIC_URL + '/' + themeId, replaceLogoPath(icon)])} 
          alt="logo"
          className="icon"
        />
    : <IconLogo
        className="icon"
      />
    ;

  return LOGO;
};

export default Icon;
